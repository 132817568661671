<script>
import Vue from "vue"
import SvgIcon from "@/components/base/SvgIcon.vue"
import { mergeData } from "vue-functional-data-merge"
import { Mission } from "@/helpers"

export default Vue.extend({
  name: "MissionTypes",
  functional: true,
  props: {
    items: {
      /** @type import('vue/types/options').Prop<string[]> */
      type: Array,
      required: true
    }
  },
  render(h, { props, data }) {
    return h("div", mergeData(data, { staticClass: "mission-types" }), [
      h(
        "ul",
        { staticClass: "mission-types__list" },
        props.items.map(item =>
          h("li", { staticClass: "mission-types__item" }, [
            h(SvgIcon, {
              props: { name: Mission.missionTypeToIcon(item) },
              staticClass: "mission-types__item-icon rtb-color-secondary"
            }),
            h("span", { staticClass: "mission-types__item-label" }, item)
          ])
        )
      )
    ])
  }
})
</script>

<style lang="scss">
.mission-types {
  &__list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 12px;
    flex-shrink: 0;

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      flex-shrink: 0;
    }

    &-label {
      font: {
        size: 14px;
        weight: 700;
      }
      color: $color_white;
    }
  }
}
</style>
