<template>
  <v-flex d-flex class="room-info">
    <v-layout row justify-center>
      <v-flex
        d-flex
        align-center
        v-for="(user, idx) in slots"
        :key="`slot-${idx}`"
        class="slot-wrap"
      >
        <v-tooltip bottom v-if="!!user && !!user.image">
          <template v-slot:activator="{ on }">
            <v-btn class="button-reset" icon v-on="on">
              <RoomInfoIcon class="slot defined" :imageUrl="user.image" />
            </v-btn>
          </template>
          <span>{{ user.firstname }} {{ user.lastname }}</span>
        </v-tooltip>
        <RoomInfoIcon class="slot" v-else />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import RoomInfoIcon from "@/components/RoomInfoIcon"

export default {
  name: "RoomInfo",
  props: {
    users: {
      type: Array,
      default: () => []
    },
    nOfSlots: {
      required: true,
      type: Number
    }
  },
  components: { RoomInfoIcon },
  computed: {
    slots() {
      const array = []
      for (let i = 0; i < this.nOfSlots; i++) array.push(this.users[i])
      return array
    }
  }
}
</script>

<style lang="scss">
.room-info {
  .slot-wrap {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
  .live-chat-dummy .outline {
    border-width: 2px;
  }
  .button-reset {
    width: auto;
    height: auto;
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
  .slot {
    min-width: 20px;
    &.defined {
      .outline {
        border-width: 0px;
        background-image: none;
      }
    }
  }
}
</style>
