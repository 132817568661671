<template>
  <v-flex>
    <RatioContainer class="live-chat-dummy-slot">
      <div class="outline">
        <slot />
      </div>
    </RatioContainer>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"

export default {
  name: "LiveChatDummySlot",
  components: {
    RatioContainer
  }
}
</script>

<style lang="scss">
.live-chat-dummy-slot {
  user-select: none;
  .outline {
    background-image: url("../assets/game-card-profile-icon-big-light.png");
    background-size: 92%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    color: $color-secondary-light;
    background-color: #acacac;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    line-height: 1.1;
    font-size: 14px;
  }
}
</style>
