<template>
  <v-flex>
    <RatioContainer class="room-info-icon">
      <div class="outline">
        <img v-if="!!imageUrl" :src="imageUrl" />
      </div>
    </RatioContainer>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"

export default {
  name: "RoomInfoIcon",
  props: {
    imageUrl: {
      type: String
    }
  },
  components: {
    RatioContainer
  }
}
</script>

<style lang="scss">
.room-info-icon {
  user-select: none;
  .outline {
    background-image: url("../assets/game-card-profile-icon-big.png");
    background-size: 92%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    color: $color-secondary-light;
    border: 2px solid $color-tertiary-dark;
    background-color: $color-tertiary-dark;
    display: flex;
    position: relative;
    border-radius: 25%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    line-height: 1.1;
    font-size: 14px;
    img {
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
</style>
